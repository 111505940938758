import Api from "./Api"
export default {
    // ***********  Brand Api Start  *********** //
    async vendorAdd(vendors) {
        return Api.post("/addVendor", vendors)
    },
    async vendorList(data){
        return Api.post("/vendors", data)
    },
    async vendorDetails(data) {
        return Api.post("/vendorDetails", data)
    },
    async vendorUpdate(editVendors) {
        return Api.put("/updateVendor", editVendors)
    },
    async vendorDelete(data){
        return Api.post("/deleteVendor",data)
    },
    async vendorStatusActive(data){
        return Api.put("/updateVendorStatus",data)
    },
    async vendorStatusInactive(data){
        return Api.put("/updateVendorStatus",data)
    },
    async vendorBulkDelete(data){
        return Api.post("/bulkVendorDelete",data)
    },
    // ***********  Brand Api End  *********** //
    // =============================================== //
    // ***********  Countries Api Start  *********** //

    async addCountry(country){
        return Api.post("/addCountry", country)
    },
    async countryList(data){
        return Api.post("/countries", data)
    },
    async countryDetails(data) {
        return Api.post("/countryDetails", data)
    },
    async countryUpdate(editCountry) {
        return Api.put("/updateCountry", editCountry)
    },
    async countryDelete(data){
        return Api.post("/deleteCountry",data)
    },
    async countryStatusActive(data){
        return Api.put("/updateCountryStatus",data)
    },
    async countryStatusInactive(data){
        return Api.put("/updateCountryStatus",data)
    },
    async countryBulkDelete(data){
        return Api.post("/bulkCountryDelete",data)
    },

    // ***********  Countries Api End  *********** //
    // =============================================== //
    // ***********  Color Api Start  *********** //

    async addColor(color){
        return Api.post("/addColor", color)
    },
    async colorList(data){
        return Api.post("/colors", data)
    },
    async colorDetails(data) {
        return Api.post("/colorDetails", data)
    },
    async colorUpdate(editColor) {
        return Api.put("/updateColor", editColor)
    },
    async colorDelete(data){
        return Api.post("/deleteColor",data)
    },
    async colorStatusActive(data){
        return Api.put("/updateColorStatus",data)
    },
    async colorStatusInactive(data){
        return Api.put("/updateColorStatus",data)
    },
    async colorBulkDelete(data){
        return Api.post("/bulkColorDelete",data)
    },

    // ***********  Color Api End  *********** //
    // =============================================== //
    // ***********  Size Api Start  *********** //

    async addSize(size){
        return Api.post("/addSize", size)
    },
    async sizeList(data){
        return Api.post("/sizes", data)
    },
    async sizeDetails(data) {
        return Api.post("/sizeDetails", data)
    },
    async sizeUpdate(editSize) {
        return Api.put("/updateSize", editSize)
    },
    async sizeDelete(data){
        return Api.post("/deleteSize",data)
    },
    async sizeStatusActive(data){
        return Api.put("/updateSizeStatus",data)
    },
    async sizeStatusInactive(data){
        return Api.put("/updateSizeStatus",data)
    },
    async sizeBulkDelete(data){
        return Api.post("/bulkSizeDelete",data)
    },

    // ***********  Size Api End  *********** //
    // =============================================== //
    // ***********  Categories Api Start  *********** //

    async addCategory(category){
        return Api.post("/addCategory", category)
    },
    async categoryList(data){
        return Api.post("/categories", data)
    },
    async categoryDetails(data) {
        return Api.post("/categoryDetails", data)
    },
    async categoryUpdate(editCategory) {
        return Api.put("/updateCategory", editCategory)
    },
    async categoryDelete(data){
        return Api.post("/deleteCategory",data)
    },
    async categoryStatusActive(data){
        return Api.put("/updateCategoryStatus",data)
    },
    async categoryStatusInactive(data){
        return Api.put("/updateCategoryStatus",data)
    },
    async categoryBulkDelete(data){
        return Api.post("/bulkCategoryDelete",data)
    },

    // ***********  Categories Api End  *********** //
    // =============================================== //
    // ***********  Sub Categories Api Start  *********** //

    async addSubCategory(sub_category){
        return Api.post("/addSubCategory", sub_category)
    },
    async subCategoryList(data){
        return Api.post("/subCategories", data)
    },
    async subCategoryDetails(data) {
        return Api.post("/subCategoryDetails", data)
    },
    async subCategoryUpdate(editSubCategory) {
        return Api.put("/updateSubCategory", editSubCategory)
    },
    async subCategoryDelete(data){
        return Api.post("/deleteSubCategory",data)
    },
    async subCategoryStatusActive(data){
        return Api.put("/updateSubCategoryStatus",data)
    },
    async subCategoryStatusInactive(data){
        return Api.put("/updateSubCategoryStatus",data)
    },
    async subCategoryBulkDelete(data){
        return Api.post("/bulkSubCategoryDelete",data)
    },

    // ***********  Sub Categories Api End  *********** //
    // =============================================== //
    // *********** List Sub Categories Api Start  *********** //

    async addListSubCategory(listSubCategory){
        return Api.post("/addListSubCategory", listSubCategory)
    },
    async listSubCategoryList(data){
        return Api.post("/listSubCategories", data)
    },
    async listSubCategoryDetails(data) {
        return Api.post("/listSubCategoryDetails", data)
    },
    async listSubCategoryUpdate(editListSubCategory) {
        return Api.put("/updateListSubCategory", editListSubCategory)
    },
    async listSubCategoryDelete(data){
        return Api.post("/deleteListSubCategory",data)
    },
    async listSubCategoryStatusActive(data){
        return Api.put("/updateListSubCategoryStatus",data)
    },
    async listSubCategoryStatusInactive(data){
        return Api.put("/updateListSubCategoryStatus",data)
    },
    async listSubCategoryBulkDelete(data){
        return Api.post("/bulkListSubCategoryDelete",data)
    },

    // *********** List Sub Categories Api End  *********** //
    // =============================================== //
    // ***********  Specification Api Start  *********** //
    async specificationAdd(specification) {
        return Api.post("/addSpecification", specification)
    },
    async specificationList(data){
        return Api.post("/specifications", data)
    },
    async specificationDetails(data) {
        return Api.post("/specificationDetails", data)
    },
    async specificationUpdate(editVendors) {
        return Api.put("/updateSpecification", editVendors)
    },
    async specificationDelete(data){
        return Api.post("/deleteSpecification",data)
    },
    async specificationStatusActive(data){
        return Api.put("/updateSpecificationStatus",data)
    },
    async specificationInactive(data){
        return Api.put("/updateSpecificationStatus",data)
    },
    async specificationBulkDelete(data){
        return Api.post("/bulkSpecificationDelete",data)
    },

    // ***********  Specification Api End  *********** //
    // =============================================== //
    // ***********  Filters Api Start  *********** //
    async filterAdd(filter) {
        return Api.post("/addFilter", filter)
    },
    async filterList(data){
        return Api.post("/filters", data)
    },
    async filterDetails(data) {
        return Api.post("/filterDetails", data)
    },
    async filterUpdate(editFilter) {
        return Api.put("/updateFilter", editFilter)
    },
    async filterDelete(data){
        return Api.post("/deleteFilter",data)
    },
    async filterStatusActive(data){
        return Api.put("/updateFilterStatus",data)
    },
    async filterStatusInactive(data){
        return Api.put("/updateFilterStatus",data)
    },
    async filterBulkDelete(data){
        return Api.post("/bulkFilterDelete",data)
    },
    // ***********  Filters Api End  *********** //
    // =============================================== //
    // ***********  Coupon Api Start  *********** //

    async addCoupon(coupon){
        return Api.post("/addCoupon", coupon)
    },
    async couponList(data){
        return Api.post("/coupons", data)
    },
    async couponDetails(data) {
        return Api.post("/couponDetails", data)
    },
    async couponUpdate(editCoupon) {
        return Api.put("/updateCoupon", editCoupon)
    },
    async couponDelete(data){
        return Api.post("/deleteCoupon",data)
    },
    async couponStatusActive(data){
        return Api.put("/updateCouponStatus",data)
    },
    async couponStatusInactive(data){
        return Api.put("/updateCouponStatus",data)
    },
    async couponBulkDelete(data){
        return Api.post("/bulkCouponDelete",data)
    },

    // ***********  Coupon Api End  *********** //
    // =============================================== //
    // ***********  Product Api Start  *********** //

    async addProduct(product){
        return Api.post("/addProduct", product)
    },
    async productList(data){
        return Api.post("/products", data)
    },
    async productListNew(data){
        return Api.post("/productsListNew", data)
    },
    async productDetails(data){
        return Api.post("/productDetails", data)
    },
    async productStatusActive(data){
        return Api.put("/updateProductStatus",data)
    },
    async productStatusInactive(data){
        return Api.put("/updateProductStatus",data)
    },
    async productBulkDelete(data){
        return Api.post("/bulkProductDelete",data)
    },
    async productDelete(data){
        return Api.post("/bulkProductDelete",data)
    },
    async addTempProductImage(data){
        return Api.post("/addTempProductImages",data)
    },
    async searchProductType(data){
        return Api.post("/searchProductType",data)
    },

    async filterSpecificationByListSubMenu(data){
        return Api.post("/filterSpecificationByListSubMenu",data)
    },
    async productUpdate(data){
        return Api.post("/updateProduct",data)
    },
    async DeleteVariant(data){
        return Api.post("/variantDelete",data)
    },
    async DeleteVariantImage(data){
        return Api.post("/variantImageDelete",data)
    },
    async productZipDelete(data){
        return Api.post("/productZipDelete",data)
    },
    async cadBlockDwgDelete(data){
        return Api.post("/cadBlockDwgDelete",data)
    },
    async DeleteCadBlock(data){
        return Api.post("/cadBlockDelete",data)
    },
    async importCSVFile(data){
        return Api.post("/import",data)
    },
    // ***********  Product Api End  *********** //
    // =============================================== //
    // ***********  Sub menu filters and specifications Api Start  *********** //
    async subMenuFitersSpecifications(products) {
        return Api.post("/subMenuFitersSpecifications", products)
    },
    // ***********  Sub menu filters and specifications Api End  *********** //
    // =============================================== //
    // ***********  Slider Api Start  *********** //
    async sliderAdd(sliders) {
        return Api.post("/addSlider", sliders)
    },
    async sliderList(data){
        return Api.post("/sliders", data)
    },
    async sliderDetails(data) {
        return Api.post("/sliderDetails", data)
    },
    async sliderUpdate(editSlider) {
        return Api.post("/updateSlider", editSlider)
    },
    async sliderDelete(data){
        return Api.post("/deleteSlider",data)
    },
    async sliderBulkDelete(data){
        return Api.post("/bulkSliderDelete",data)
    },
    async sliderBulkActive(data){
        return Api.put("/updateSliderStatus",data)
    },
    async sliderBulkInActive(data){
        return Api.put("/updateSliderStatus",data)
    },

    // ***********  Slider Api End  *********** //
    // =============================================== //
    // ***********  Shop by Rooms Api Start  *********** //
    async shopByRoomAdd(shopByRoom) {
        return Api.post("/addShopByRoom", shopByRoom)
    },
    async shopByRoomList(data){
        return Api.post("/shopByRooms", data)
    },
    async shopByRoomDetails(data) {
        return Api.post("/shopByRoomDetails", data)
    },
    async shopByRoomUpdate(editShopByRoom) {
        return Api.post("/updateShopByRoom", editShopByRoom)
    },
    async shopByRoomDelete(data){
        return Api.post("/deleteShopByRoom",data)
    },
    async shopByRoomBulkDelete(data){
        return Api.post("/bulkShopRoomDelete",data)
    },
    async shopByRoomBulkActive(data){
        return Api.put("/updateShopByRoomStatus",data)
    },
    async shopByRoomBulkInActive(data){
        return Api.put("/updateShopByRoomStatus",data)
    },

    // ***********  Shop by Rooms Api End  *********** //
    // =============================================== //
    // ***********  CMS Api Start  *********** //
    async pageDetails(data){
        return Api.post("/pageDetails", data)
    },
    async updatePageDetails(data){
        return Api.put("/updatePageDetails", data)
    },
    // ***********  CMS Api End  *********** //
    // =============================================== //
    // ***********  Collections Api Start  *********** //
    async collectionAdd(collection) {
        return Api.post("/addCollection", collection)
    },
    async collectionList(data){
        return Api.post("/collections", data)
    },
    async collectionDetails(data) {
        return Api.post("/collectionDetails", data)
    },
    async collectionUpdate(editShopByRoom) {
        return Api.post("/updateCollection", editShopByRoom)
    },
    async collectionDelete(data){
        return Api.post("/bulkCollectionDelete",data)
    },
    async collectionBulkDelete(data){
        return Api.post("/bulkCollectionDelete",data)
    },
    async collectionBulkActive(data){
        return Api.put("/updateCollectionStatus",data)
    },
    async collectionBulkInActive(data){
        return Api.put("/updateCollectionStatus",data)
    },

    // ***********  Collections Api End  *********** //
    // =============================================== //
    // *********** Product Collection Api Start  *********** //
    async productCollectionAdd(productCollection) {
        return Api.post("/addCollectionProducts", productCollection)
    },

    async collectionProductList(){
        return Api.get("/collectionProductsList")
    },
    async collectionProductDetails(data) {
        return Api.post("/productsByCollection", data)
    },
    async collectionProductBulkDelete(data){
        return Api.post("/deleteCollectionProduct",data)
    },


    // ***********  Collections Api End  *********** //


    // ***********  User Api Start  *********** //
    async userList(){
        return Api.get("/usersList")
    },
    async activeUsersList(){
        return Api.get("/activeUsersList")
    },
    async approveUsers(data){
        return Api.put("/approveUsers", data)
    },
    async updateUserStatus(data){
        return Api.put("/updateUserStatus", data)
    },
    async userDetails(data){
        return Api.post("/userDetails", data)
    },
    async updateDiscountPercentage(data){
        return Api.post("/updateDiscountPercentage", data)
    },
    async userBulkDelete(data){
        return Api.post("/bulkUserDelete", data)
    },

    // ***********  User Api End  *********** //
    // =============================================== //
    // ***********  Section Api Start  *********** //
    async sectionAdd(section) {
        return Api.post("/addSection", section)
    },
    async sectionList() {
        return Api.get("/sections")
    },
    async sectionDetails(data) {
        return Api.post("/sectionDetails", data)
    },
    async updateSection(data) {
        return Api.put("/updateSection", data)
    },
    async deleteSection(data) {
        return Api.post("/deleteSection", data)
    },
    // ***********  Section Api End  *********** //
    // =============================================== //
    // ***********  Page Api Start  *********** //
    async sectionPageAdd(section) {
        return Api.post("/addSectionPage", section)
    },
    async sectionPageList() {
        return Api.get("/pagesList")
    },
    async sectionPageDetails(data) {
        return Api.post("/pageData", data)
    },
    async updateSectionPage(data) {
        return Api.put("/updateSectionPage", data)
    },
    async deleteSectionPage(data) {
        return Api.post("/deletePage", data)
    },
    // ***********  Section Api End  *********** //
    // =============================================== //
    // ***********  Order Api Start  *********** //

    async orderList(data){
        return Api.post("/orders", data)
    },
    async orderDetails(data){
        return Api.post("/orderDetails", data)
    },
    async orderDeliveryList(data){
        return Api.post("/orderDeliveryList", data)
    },
    async addOrderDelivery(delivery){
        return Api.post("/addOrderDelivery", delivery)
    },

    async addOrderPayment(payment){
        return Api.post("/addOrderPayment", payment)
    },
    async orderPaymentsList(data){
        return Api.post("/orderPaymentsList", data)
    },
    async orderPaymentConfirm(data){
        return Api.post("/paymentConfirm", data)
    },
    async orderPaymentReject(data){
        return Api.post("/paymentConfirm", data)
    },

    async deliveryDelete(data){
        return Api.post("/deliveryDelete", data)
    },

    async paymentDelete(data){
        return Api.post("/paymentDelete", data)
    },
    async paymentMode(data){
        return Api.post("/paymentModes", data)
    },
    async cancelTotalOrder(data){
        return Api.post("/cancelTotalOrder", data)
    },
    async cancelProductOrder(data){
        return Api.post("/cancelOrderItems", data)
    },


    // ***********  Order Api End  *********** //

    // ***********  List SubMenu Filters and Specification Api Start  *********** //
    async listSubmenuFiltersAndSpecificationAdd(section) {
        return Api.post("/listSubMenuFitersSpecifications", section)
    },
    async listSubmenuFiltersAndSpecificationList() {
        return Api.get("/getFiltersSpecifications")
    },
    async listSubmenuFiltersAndSpecificationDelete(data) {
        return Api.post("/deleteFiltersSpecifications",data)
    },
    async listSubmenuFiltersAndSpecificationUpdate(data) {
        return Api.put("/updateFitersSpecifications",data)
    },
    async listSubmenuFiltersAndSpecificationBulkDelete(data) {
        return Api.post("/bulkFilterSpecificationDelete",data)
    },
    // ***********  List SubMenu Filters and Specification Api End  *********** //


    // ***********  Admin Users Api Start  *********** //
    async adminSectionAdd(section) {
        return Api.post("/addAdminSection", section)
    },
    async adminSectionList() {
        return Api.get("/adminSections")
    },
    async adminSectionDelete(data) {
        return Api.post("/deleteAdminSection",data)
    },
    async adminSectionDetails(data) {
        return Api.post("/adminSectionDetails",data)
    },
    async adminSectionUpdate(data) {
        return Api.put("/updateAdminSection",data)
    },


    async adminSectionPageAdd(section) {
        return Api.post("/addAdminSectionPages", section)
    },
    async adminSectionPageList() {
        return Api.get("/adminPages")
    },
    async adminSectionPageDelete(data) {
        return Api.post("/deleteAdminPage",data)
    },
    async adminSectionPageDetails(data) {
        return Api.post("/adminPageData",data)
    },
    async adminSectionPageUpdate(data) {
        return Api.put("/updateAdminPage",data)
    },


    async adminRoleAdd(role) {
        return Api.post("/addAdminRole", role)
    },

    async userPayment(payment) {
        return Api.post("/userPaymentsList", payment)
    },

    async adminRoleList() {
        return Api.get("/adminRoles")
    },
    async adminRoleDetails(data) {
        return Api.post("/adminRoleDetails", data)
    },
    async adminRoleUpdate(data) {
        return Api.put("/updateAdminRole", data)
    },
    async adminRoleDelete(data) {
        return Api.post("/adminRolesDelete", data)
    },


    async adminUserAdd(user) {
        return Api.post("/addAdminUser", user)
    },
    async adminUserList(data) {
        return Api.post("/adminUsers", data)
    },
    async adminUserDetails(data) {
        return Api.post("/adminUserDetails", data)
    },
    async adminUserUpdate(data) {
        return Api.put("/adminUpdateUser", data)
    },
    async adminUserDelete(data) {
        return Api.post("/adminDeleteUser", data)
    },

    // ***********  Admin Users Api End  *********** //

    async dashboard() {
        return Api.get("/dashboard")
    },
    async activityLogs() {
        return Api.get("/activityLogs")
    },



    login(data){
        return Api.post("/login",data)
    },
    changePassword(data){
        return Api.put("/changePassword",data)
    },
}